<template>
  <ChangePasswordWrapper>
    <sdCards>
      <template v-slot:title>
        <div class="setting-card-title">
          <sdHeading as="h4">Wachtwoord wijzigen</sdHeading>
        </div>
      </template>
      <a-row type="flex" justify="center">
        <a-col :lg="12" :sm="20" :xs="24">
          <BasicFormWrapper>
            <a-form ref="changePasswordForm" :model="formState" @finish="handelChangePassword" layout="vertical">
              <a-form-item name="oldPassword" label="Huidige wachtwoord">
                <a-input type="password" v-model:value="formState.oldPassword" :rules="{required: true,message: 'Dit veld is verplicht'}"/>
              </a-form-item>
              <a-form-item name="newPassword" label="Nieuw wachtwoord">
                <a-input-password type="password" v-model:value="formState.newPassword" :rules="{required: true,message: 'Dit veld is verplicht'}"/>
              </a-form-item>
<!--              <p class="input-message">Minimum 6 characters</p>-->
              <a-form-item>
                <div class="setting-form-actions">
                  <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingChangePassword" :disabled="loadingChangePassword"> Opslaan</a-button>
                </div>
              </a-form-item>
            </a-form>
          </BasicFormWrapper>
        </a-col>
      </a-row>
    </sdCards>
  </ChangePasswordWrapper>
</template>

<script>
import { ChangePasswordWrapper } from './style';
import { reactive, defineComponent, ref, computed } from 'vue';
import {BasicFormWrapper} from "../../styled";
import {useStore} from "vuex";

const Password = defineComponent({
  name: 'Password',
  components: { ChangePasswordWrapper, BasicFormWrapper },
  setup() {
    const {state, dispatch} = useStore();
    const changePasswordForm = ref();
    const loadingChangePassword = computed(() => state.auth.loadingChangePassword);
    const formState = reactive({
      oldPassword : '',
      newPassword: '',
    });

    const handelChangePassword = async () => {
      await dispatch('changePassword', formState)
      formState.oldPassword = '';
      formState.newPassword = '';
    };

    return {
      formState,
      handelChangePassword,
      changePasswordForm,
      loadingChangePassword,
    };
  },
});

export default Password;
</script>
